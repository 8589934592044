<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <b-form-group
            id="input-group-consultation-date"
            label="Tanggal Konsultasi:"
            label-for="input-consultation-date"
          >
            <v-menu
              v-model="menuConsultationDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-consultation-date"
                  v-model="form.consultation_date"
                  label="Tanggal Konsultasi:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.consultation_date"
                @input="menuConsultationDate = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.consultation_date }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-teacher-1"
            label="Dosen Pembimbing:"
            label-for="input-teacher-1"
          >
            <treeselect
              v-model="form.teacher_1_id"
              :multiple="false"
              placeholder="Pilih Dosen PEmbimbing"
              :options="teachers"
            />
            <small class="text-danger">{{ error.teacher_1_id }}</small>
          </b-form-group>

          <!-- <b-form-group id="input-group-teacher-2">
            <label for="input-teacher-2"
              >Dosen 2: <em class="text-muted">opsional</em></label
            >
            <treeselect
              v-model="form.teacher_2_id"
              :multiple="false"
              placeholder="Pilih Dosen 2"
              :options="teachers"
            />
            <small class="text-danger">{{ error.teacher_2_id }}</small>
          </b-form-group> -->

          <b-form-group id="input-group-consultation-content">
            <label for="input-consultation-content">Isi Konsultasi:</label>
            <b-form-textarea
              id="input-consultation-content"
              v-model="form.consultation_content"
              placeholder="Isi konsultasi"
              rows="4"
              max-rows="8"
              :disabled="currentUser.role_id == 2 ? false : true"
            ></b-form-textarea>
            <small class="text-danger">{{ error.consultation_content }}</small>
          </b-form-group>

          <b-form-group id="input-group-teacher-notes">
            <label for="input-teacher-notes">Catatan Dosen:</label>
            <b-form-textarea
              id="input-teacher-notes"
              v-model="form.teacher_notes"
              placeholder="Catatan Dosen"
              rows="4"
              max-rows="8"
              :disabled="
                currentUser.role_id == 1 || currentUser.role_id == 4
                  ? false
                  : true
              "
            ></b-form-textarea>
            <small class="text-danger">{{ error.teacher_notes }}</small>
          </b-form-group>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="gotoBack"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    routeBack: {
      type: String,
      default: "/academic-guidance/list",
    },
    purposeMessage: String,
    purpose: String,
  },
  data() {
    return {
      currentUser: getUser(),
      error: {
        student_id: "",
        student_name: "",
        teacher_1_id: "",
        teacher_2_id: "",
        consultation_date: "",
        consultation_content: "",
        teacher_notes: "",
        status_id: 0,
        verification_date: "",
      },
      //   others
      teachers: [],
      menuConsultationDate: false,
    };
  },
  methods: {
    gotoBack() {
      this.$router.push({path: this.routeBack, query: this.$route.query})
    },
    async getTeacherOption() {
      let essay_mentor = getUser().essay_mentor_1;
      essay_mentor += "," + getUser().essay_mentor_2;
      //   if(getUser().essay_mentor_2 != 0){
      //   }
      console.log("essay", essay_mentor);
      let response = await module.setTreeSelect(
        "api/users",
        `?role_id=1&id_multiple=${essay_mentor.toString()}`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teachers = response.data;
        this.teachers.unshift({
          label: "Pilih Dosen",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        let message = "";
        if (this.purpose == "add") {
          message = "Berhasil menambah " + this.purposeMessage;
        } else {
          message = "Berhasil mengubah " + this.purposeMessage;
        }
        // Success
        Swal.fire({
          title: "Berhasil",
          text: message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$router.push(this.routeBack);
      }
    },
  },
  mounted() {
    this.getTeacherOption();
  },
};
</script>